// FBSage - Firebelly 2015
/*jshint latedef:false*/

// Good Design for Good Reason for Good Namespace
var FBSage = (function($) {

  var $window,
      $body,
      $document,
      $siteHeader,
      $siteNav,
      $searchForm,
      siteNavMenus,
      siteNavSubMenus,
      $tallestSubMenu,
      modalContainer,
      breakpointIndicatorString,
      breakpoint_xl,
      breakpoint_lg,
      breakpoint_nav,
      breakpoint_md,
      breakpoint_sm,
      breakpoint_xs,
      breakpoints = [],
      breakpointClasses = ['xs','sm','md','lg','nav','xl'],
      resizeTimer,
      transitionElements,
      loadingTimer,
      $loadingSpinner;

  function _init() {
    // Cache some common DOM queries
    $window = $(window);
    $body = $('body');
    $document = $(document);
    $siteHeader = $('#site-header');
    $searchForm = $('#search-form');
    $siteNav = $('#site-nav');
    siteNavMenus = $siteNav.children('.-inner').children('ul');
    siteNavSubMenus = $siteNav.find('.sub-menu');
    $tallestSubMenu = _getTallestSubMenu();
    modalContainer = '<div id="modal" class="modal"><button class="modal-close">close</button><div class="modal-content"></div></div>';
    $loadingSpinner = '<p id="loading-spinner">Loading—Loading—</p>';

    // DOM is loaded
    $body.addClass('loaded');

    // Check if there's no active state in #site-nav and a breadcrumb on the page
    if ($('#site-nav .active').length===0 && $('.breadcrumbs li').length > 0) {
      var parentPageTitle = $('.breadcrumbs li:first').text().trim();
      $('#site-nav li').each(function() {
        var navItemTitle = $(this).find('a:first').text().trim();
        // Highlight parent item in nav if it matches first item in breadcrumb (from $top_ancestor set in page-header.php)
        if (navItemTitle === parentPageTitle) {
          $(this).addClass('active');
        }
      });
    }

    // Set screen size vars
    _resize();

    // Transition elements to enable/disable on resize
    transitionElements = [$siteHeader, $siteNav, siteNavMenus];

    // Resize selects to selected item so rightside arrow is flush with text
    $('.filter-select').each(function() {
      var $this = $(this);
      var tmp = $('<span>' + $this.find('select :selected').text() + '</span>').appendTo($this);
      var tmp_width = tmp.width() + 5;
      tmp.remove();
      $this.find('select').width(tmp_width);
      // Wait a second to apply .sized to trigger showing the icon-arrow
      setTimeout(function() {
        $this.addClass('sized');
      }, 250);
    });

    // Selects that jump to URLs
    $(document).on('change', 'select.jumpSelect', function(e) {
      $(this).css('width', '');
      var jumpTo = $(this).find(':selected').val();
      if ($(this).parents('#filters-section').length) {
        jumpTo = jumpTo + '#filters-section';
      }

      location.href = jumpTo;
    });

    // Fit them vids!
    $('main').fitVids();

    _initBigClicky();
    _shrinkSiteNav();
    _initNav();
    // _initHoverWords();
    _initImageGalleries();
    _initWorkCarousels();
    _initHistoryCarousels();
    _initCircleType();
    _initSearch();
    _initLoadMore();
    _initAjaxModal();
    _initFormActions();
    _initAccordions();
    _initHoverPairs();

    // Esc handlers
    $(document).keyup(function(e) {
      if (e.keyCode === 27) {
        if ($body.is('.search-open')) {
          _hideSearch();
        }
        if ($body.is('.modal-open')) {
          _closeModal();
        }
        if ($('#menu-toggle').is('.menu-open')) {
          _hideSiteNav();
        }
      }
    });

    // Smoothscroll links
    $('a.smoothscroll').click(function(e) {
      e.preventDefault();
      var href = $(this).attr('href');
      _scrollBody($(href));
    });

    // Scroll down to hash afer page load
    $window.load(function() {
      if (window.location.hash) {
        _scrollBody($(window.location.hash));
      }
    });

    if ($body.is('.home.page')) {
      // Randomly rotate dynamic word
      var dynamicWordAt = 0;
      var dynamicWordTimer = setInterval(function() {
        dynamicWordAt = dynamicWordAt === dynamicWords.length - 1 ? 0 : dynamicWordAt + 1;
        $('h2.page-headline .dynamic-word').html(dynamicWords[dynamicWordAt]);
      }, 2500);
    }

    // Competitions page
    if ($body.is('.competitions')) {
      _setStickyCompetitions();
      $window.scroll(_setStickyCompetitions);
      $window.resize(_setStickyCompetitions);
    }

  } // end init()

  function _disableScroll() {
    $body.attr('data-st', $window.scrollTop());
    $body.addClass('no-scroll');
  }

  function _enableScroll() {
    $body.removeClass('no-scroll');
    $window.scrollTop($body.attr('data-st'));
    $body.attr('data-st', '');
  }

  function _scrollBody(element, duration, delay) {
    var offset = $('#site-header').outerHeight() + 16;

    if ($('#wpadminbar').length) {
      wpOffset = $('#wpadminbar').height();
    } else {
      wpOffset = 0;
    }
    element.velocity("scroll", {
      duration: duration,
      delay: delay,
      offset: -offset - wpOffset
    }, "easeOutSine");
  }

  function _initSearch() {
    $('.search-toggle').on('click', function(e) {
      e.preventDefault();
      if ($(this).is('.-active')) {
        _hideSearch();
      } else {
        _showSearch();
      }
    });

    $('.search-close').on('click', function(e) {
      _hideSearch();
    });
  }

  function _showSearch() {
    if ($body.is('.menu-open')) {
      _hideSiteNav();
    }
    $body.addClass('search-open');
    _disableScroll();
    $('.search-toggle').addClass('-active');
    $searchForm.velocity({
      opacity: 1
    }, {
      display: 'block',
      complete: function() {
        $searchForm.addClass('-active').find('#s').focus();
      }
    });
  }

  function _hideSearch() {
    $body.removeClass('search-open no-scroll');
    _enableScroll();
    $searchForm.removeClass('-active').find('input').first().blur();
    $searchForm.velocity({ opacity: 0 }, { display: 'none' });
    $('.search-toggle').removeClass('-active');
  }

  // Larger clicker areas ftw (w/ support for target and ctrl/cmd+click)
  function _initBigClicky() {
    $document.on('click', '.bigclicky', function(e) {
      if (!$(e.target).is('a')) {
        e.preventDefault();
        var link = $(this).find('h1:first a,h2:first a');
        var href = link.attr('href');
        if (href) {
          if (e.metaKey || link.attr('target')) {
            window.open(href);
          } else {
            location.href = href;
          }
        }
      }
    });
  }

  // Show/hide nav when user scrolls
  function _shrinkSiteNav() {
    function addScrolledClass() {
      // Only do it after nav breakpoint
      if (!breakpoint_nav || $body.is('.no-scroll')) {
        return;
      }

      var st = window.pageYOffset,
          offset = $siteHeader.outerHeight();

      if (st >= 48 && !$siteHeader.is('.menu-open') && !$siteHeader.is('.sub-menu-open')) {
        _disableSingleTransition($siteNav,250);
        $siteHeader.addClass('scrolled');
      } else if (st <= offset) {
        $siteHeader.removeClass('scrolled');
        _hideSiteNav();
      }
    }

    addScrolledClass();

    $window.scroll(function() {
      addScrolledClass();
    });
  }

  // Handles main nav
  function _initNav() {
    // SEO-useless nav toggler
    $('<div id="menu-toggle" class="menu-toggle"><div class="menu-bar -one"></div><div class="menu-bar -two"></div><span class="sr-only">Menu</span></div>')
      .prependTo($siteHeader.children('.-inner'))
      .on('click', function(e) {
        if ($siteNav.is('.-active')) {
          _hideSiteNav();
        } else {
          _showSiteNav();
        }
      });

    // Adding functionality to sub-nav
    $siteNav.find('.menu-item-has-children > a').each(function(e) {
      var pageText = $(this).html();
      pageText = pageText.replace(' ', ' <br>');
      $(this).html(pageText);
      $(this).append('<button class="sub-nav-button" aria-hidden="true"><svg class="icon icon-arrow-small" aria-hidden="true" role="presentation"><use xlink:href="#icon-arrow-small"/></svg></button>');
    });

    // Toggling sub-nav from the mobile menu
    $siteNav.on('click', '.menu-item-has-children > a', function(e) {
      e.preventDefault();
      if (!breakpoint_nav) {
        var $listItem = $(this).parent('li');

        if ($listItem.is('.-active')) {
          _contractSubNav($listItem);
        } else {
          _expandSubNav($listItem);
        }
      }
    });

    //Open/close sub-nav on hover of main-nav
    $siteNav.on('mouseenter', '#menu-main', function(e) {
      if (!breakpoint_nav || $siteHeader.is('.scrolled')) {
        return;
      }

      var $listItem = $(this).parent('li');
      _expandSubNav($listItem);
    }).on('mouseleave', '#menu-main', function(e) {
      if (!breakpoint_nav || $siteHeader.is('.scrolled')) {
        return;
      }

      var $listItem = $(this).parent('li');
      _contractSubNav($listItem);

      // Set to scrolled style if user scrolled while a
      // sub-menu was activated
      var st = window.pageYOffset,
          offset = $siteHeader.outerHeight();
      if (st >= offset) {
        _disableSingleTransition($siteNav,250);
        _disableSingleTransition($('.sub-nav-background'),250);
        $siteHeader.addClass('scrolled');
      }
    });

    // Close open nav when clicking away
    $(document).on('click', 'body.menu-open', function(e) {
      var $target = $(e.target);
      if (!breakpoint_nav && $target.is('.site-nav') && !$target.parents('.site-nav-inner').length) {
        _hideSiteNav();
      }
      if (!$target.is('.site-header') && !$target.parents('.site-header').length) {
        _hideSiteNav();
      }
    });
  }

  function _getTallestSubMenu() {
    var $tallestSubMenu = $(siteNavSubMenus[0]);
    for (var i=1;i < siteNavSubMenus.length;i++) {
      if ($(siteNavSubMenus[i]).outerHeight() > $tallestSubMenu.outerHeight()) {
        $tallestSubMenu = $(siteNavSubMenus[i]);
      }

      if (i === siteNavMenus.length) {
        return $tallestSubMenu;
      }
    }
  }

  function _expandSubNav($listItem) {
    $listItem.addClass('-active');
    $body.addClass('sub-menu-open');

    if (!breakpoint_nav) {
      // Close others that are open
      _contractSubNav($('.menu-item-has-children.-active').not($listItem));

      $listItem.find('.sub-nav-button').addClass('-active');
      $listItem.find('.sub-menu').velocity('slideDown', {
        easing: 'easeOutQuart',
        duration: 250
      });
    } else {
      $siteHeader.addClass('sub-menu-open');
      $siteNav.addClass('sub-menu-open');
      // If not scrolled yet figure out where to position the
      // background block of the sub-menus
      if (!$siteHeader.is('.scrolled')) {
        var snbH = $tallestSubMenu.outerHeight() - 39;
        for (var i=0;i < siteNavSubMenus.length;i++) {
          $(siteNavSubMenus[i]).css('min-height', $tallestSubMenu.outerHeight());
        }
        $('.sub-nav-background').css('height', snbH);
      }
    }
  }

  function _contractSubNav($listItem) {
    $listItem.removeClass('-active');
    $body.removeClass('sub-menu-open');

    if (!breakpoint_nav) {
      $listItem.find('.sub-nav-button').removeClass('-active');
      $listItem.find('.sub-menu').velocity('slideUp', {
        easing: 'easeOutQuart',
        duration: 250
      });
    } else {
      $siteHeader.removeClass('sub-menu-open');
      $siteNav.removeClass('sub-menu-open');
      if (!$siteHeader.is('.scrolled')) {
        $('.sub-nav-background').css('height', 0);
      }
    }
  }

  function _showSiteNav() {
    $siteNav.attr('style', '');
    $('#menu-toggle').addClass('menu-open');
    $body.addClass('menu-open');
    $siteHeader.addClass('menu-open');
    $siteNav.addClass('-active');
  }

  function _hideSiteNav() {
    $('#menu-toggle').removeClass('menu-open');
    $body.removeClass('menu-open');
    $siteHeader.removeClass('menu-open');
    $siteNav.removeClass('-active');
  }

  function _initHoverWords() {
    if (!$('.hover-words-container').length) {
      return;
    }

    var words = ['culture','community','type','typography','design','growth'];
    var wordContainers = $('.hover-words-container');

    $.each(wordContainers, function(index, element) {
      var $wordContainer = $(element);
      var wordsToCheck = $wordContainer.text();

      $.each(words, function(index, word) {
        if (wordsToCheck.indexOf(words[index])) {
          word = words[index];
          $.ajax({
            url: wp_ajax_url,
            async: true,
            method: 'post',
            data: {
              action: 'hover_images',
              word: word,
            },
            success: function(data) {
              $wordContainer.html($wordContainer.html().replace(words[index],' <span class="hover-word"><span class="word">'+word+'</span>'+data+'</span> '));
            }
          });
        }
      });
    });
  }

  function _initImageGalleries() {
    if (!$('.image-gallery').length) {
      return;
    }

    var $imageGallery = $('.image-gallery');
    var $items = $('.gallery-item');
    var spacer = '<div class="gallery-item spacer"></div>';

    for (var i=0;i<$items.length;i++) {
      if (i % 3 === 0) {
        $($items[i]).after(spacer);
      }
    }

    var $masonryGrid = $imageGallery.masonry({
      itemSelector: '.gallery-item',
      columnWidth: '.grid-sizer',
    });

    $masonryGrid.imagesLoaded().progress( function() {
      $masonryGrid.masonry('layout');
    });

  }

  function _updateFlickityStatus($carousel) {
    var flkty = $carousel.data('flickity');
    var $carouselStatus = $carousel.find('.carousel-status');
    var cellNumber = flkty.selectedIndex + 1;
    $carouselStatus.text( cellNumber + ' / ' +  flkty.slides.length );
  }

  function _initWorkCarousels() {
    if (!$('.work-carousel').length) {
      return;
    }

    var $workCarousel = $('.work-carousel').flickity({
      pageDots: false,
      cellAlign: 'right',
      imagesLoaded: true,
      lazyLoad: 1,
      adaptiveHeight: true,
      cellSelector: '.work-carousel-image',
      arrowShape: 'M24.1,71c-0.6-6.1-1.7-13.6-5.2-18.8H100v-4.3H18.8c3.5-5.2,4.9-13,5.2-18.8C18,38,9.9,45.8,0,50.1 C9.9,54.2,17.4,62.3,24.1,71z'
    });

    // Carousel status/counter
    $('.work-carousel').append('<p class="carousel-status"></p>');

    _updateFlickityStatus($workCarousel);
    $workCarousel.on( 'change.flickity', function() {
      _updateFlickityStatus($workCarousel);
    });

    // Rest styles from image-wrap when child image is loaded
    $workCarousel.on('lazyLoad.flickity', function(event,cellElement) {
      var img = event.originalEvent.target;
      $(img).closest('.image-wrap').addClass('lazyloaded').attr('style', '');
    });
  }

  function _initHistoryCarousels() {
    if (!$('.history-section').length) {
      return;
    }

    var $spanCarousel = $('.era-span-container').flickity({
      pageDots: false,
      prevNextButtons: false,
      adaptiveHeight: true,
    });

    // Add status counter
    $('.era-content-container').append('<p class="carousel-status"></p>');

    var $contentCarousel = $('.era-content-container').flickity({
      cellSelector: '.era-content',
      sync: '.era-span-container',
      adaptiveHeight: true,
      pageDots: false,
      arrowShape: 'M24.1,71c-0.6-6.1-1.7-13.6-5.2-18.8H100v-4.3H18.8c3.5-5.2,4.9-13,5.2-18.8C18,38,9.9,45.8,0,50.1 C9.9,54.2,17.4,62.3,24.1,71z'
    });

    _updateFlickityStatus($contentCarousel);
    $contentCarousel.on( 'change.flickity', function() {
      _updateFlickityStatus($contentCarousel);
    });
  }

  function _initCircleType() {
    var circleTypeElements = document.getElementsByClassName('circletype');

    for (var i=0;i<circleTypeElements.length;i++) {
      new CircleType(circleTypeElements[i]);
      $(circleTypeElements[i]).addClass('complete');
    }
  }

  function _showLoadingSpinner() {
    if (!$('#loading-spinner').length) {
      $body.append($loadingSpinner);
      new CircleType(document.getElementById('loading-spinner'));
    }

   // Set a timeout to show the loading spinner if it takes too long to load
    loadingTimer = setTimeout(function() {
      $body.addClass('loading');
      $('#loading-spinner').velocity({ opacity: 1 }, { display: 'block' });
    }, 200);
  }

  function _hideLoadingSpinner() {
    clearTimeout(loadingTimer);
    if (!$('#loading-spinner').length) {
      return;
    }

    $('#loading-spinner').velocity({ opacity: 0 }, {
      display: 'none',
      complete: function() {
        $body.removeClass('loading');
      }
    });
  }

  function _initLoadMore() {
    $document.on('click', '.load-more a', function(e) {
      e.preventDefault();
      var $load_more = $(this).closest('.load-more');
      var post_type = $load_more.attr('data-post-type') ? $load_more.attr('data-post-type') : 'work';
      var page = parseInt($load_more.attr('data-page-at'));
      var per_page = parseInt($load_more.attr('data-per-page'));
      var competition = $load_more.attr('data-competition');
      var competition_category = $load_more.attr('data-competition-category');
      var more_container = $load_more.parents('section,main').find('.load-more-container');
      var data_ids = more_container.find('article').map(function() { return $(this).attr('data-id') ;}).get().join(',');
      _showLoadingSpinner();

      $.ajax({
          url: wp_ajax_url,
          method: 'post',
          data: {
            action: 'load_more_posts',
            post_type: post_type,
            page: page+1,
            per_page: per_page,
            competition: competition,
            exclude_ids: data_ids,
            competition_category: competition_category
          },
          success: function(data) {
            var $data = $(data);
            if (loadingTimer) { _hideLoadingSpinner(); }
            more_container.append($data);
            if (more_container.is('.image-gallery')) {
              _initCircleType();
              more_container.masonry('appended', $data);
              more_container.imagesLoaded().progress( function() {
                more_container.masonry('layout');
              });
            }
            $load_more.attr('data-page-at', page+1);

            // Hide load more if last page
            if ($load_more.attr('data-total-pages') <= page + 1) {
              $load_more.addClass('hide');
            }
          }
      });
    });
  }

  function _initAjaxModal() {

    $document.on('click', '.ajax-modal a', function(e) {
      e.preventDefault();
    });

    $document.on('click', '.ajax-modal', function(e) {
      e.preventDefault();
      var post_id = $(this).attr('data-id') ? $(this).attr('data-id') : '';
      var template = $(this).attr('data-template') ? $(this).attr('data-template') : 'work';
      _showLoadingSpinner();

      $.ajax({
          url: wp_ajax_url,
          method: 'post',
          data: {
            action: 'modal_posts',
            post_id: post_id,
            template: template,
          },
          success: function(data) {
            if (loadingTimer) { _hideLoadingSpinner(); }
            _openModal(data);
          }
      });
    });

    // Close Modal on click
    $document.on('click', '.modal-close', function(e) {
      e.preventDefault();
      _closeModal();
    });
  }

  function _openModal(data) {
    if (!$('#modal').length) {
      $body.append(modalContainer);
    }

    $('#modal').find('.modal-content').html(data);
    $body.addClass('modal-open');
    _disableScroll();
    $('#modal').css('display', 'block');
    _initWorkCarousels();

    $('#modal').velocity(
      { opacity: 1 }, {
        complete: function() {
          $('#modal').addClass('-active');
        }
    });
  }

  function _closeModal() {
    if (!$body.is('.modal-open')) {
      return;
    }

    $body.removeClass('modal-open');
    _enableScroll();

    $('#modal').velocity(
      { opacity: 0 }, {
      display: "none",
        complete: function() {
          $('#modal').removeClass('-active');
          $('#modal').find('.modal-content').html('');
        }
    });
  }

  function _setStickyCompetitions() {
    if (!breakpoint_lg) { return; }

    var competitions = $('.competition');
    var st = $window.scrollTop();

    for (var i=0;i<competitions.length;i++) {
      var $competition = $(competitions[i]);

      if (st >= $competition.offset().top && st + $window.outerHeight() <= $competition.offset().top + $competition.outerHeight()) {
        $competition.addClass('sticky');
        $competition.removeClass('scrolled-past');
      } else if (st + $window.outerHeight() > $competition.offset().top + $competition.outerHeight()) {
        $competition.removeClass('sticky');
        $competition.addClass('scrolled-past');
      } else {
        $competition.removeClass('sticky');
      }
    }
  }

  function _initFormActions() {
    $('form input, form textarea, form select').on('focus', function() {
      $(this).closest('.input-wrap, .select-wrap').addClass('-focus');
    }).on('blur', function() {
      $(this).closest('.input-wrap, .select-wrap').removeClass('-focus');
    }).on('change', function() {
      if($(this).val() && $(this).val() !== '') {
        $(this).closest('.input-wrap, .select-wrap').addClass('filled');
      } else {
        $(this).closest('.input-wrap, .select-wrap').removeClass('filled');
      }
    });

    // Load in membership forms on radio click
    $('form#membership-select input').on('change', function(e) {
      var membership_url = $(this).val();
      document.location.href = membership_url + '#membership-options';
    });

    // Fix broken
  }

  function _footerFlash(message) {
    // Set messaging and show footer flash
    $('.footer-flash h3').text(message);
    $('.footer-flash').addClass('-active');
  }

  function _initAccordions() {
    if ($('.accordion').length) {
      var accordions = $('.accordion');

      for (var i=0;i<accordions.length;i++) {
        var $accordion = $(accordions[i]);
        if (!$accordion.is('.-active')) {
          _hideAccordion($accordion);
        }
        // Hidden with css by default, show when done checking
        $accordion.css('visibility', 'visible');
      }

      $('.accordion').on('click', '.accordion-toggle', function(e) {
        var $accordion = $(this).parent('.accordion');

        if ($accordion.parents('.accordion-group').length && $('.accordion').not($accordion).is('.-active')) {
          _collapseAccordion($accordion.parents('.accordion-group').find('.accordion.-active').not($accordion));
        }

        if ($accordion.is('.-active')) {
          _collapseAccordion($accordion);
        } else {
          _expandAccordion($accordion);
        }
      });

    }
  }

  // Reset accordions based on breakpoints
  function _resetAccordions() {
    if ($('.accordion').length) {
      var accordions = $('.accordion');
      for (var i=0;i<accordions.length;i++) {
        var $accordion = $(accordions[i]);
        // Contracted/expand accordions depending on screen size
        // and their assigned '.expanded-xx' class
        for (var b=0;b<breakpoints.length;b++){
          if ($accordion.is('.expanded-'+breakpointClasses[b])) {
            if (!breakpoints[b]) {
              _hideAccordion($accordion);
            } else {
              _showAccordion($accordion);
            }
          }
        }
      }
    }
  }

  function _deactivateAccordion($accordion) {
    $accordion.removeClass('-active');
  }

  function _activateAccordion($accordion) {
    $accordion.addClass('-active');
  }

  function _collapseAccordion($accordion) {
    _deactivateAccordion($accordion);
    $accordion.find('.accordion-content').velocity('slideUp', {
      easing: 'easeOutQuart',
      duration: 250
    });
  }

  function _expandAccordion($accordion) {
    _activateAccordion($accordion);
    $accordion.find('.accordion-content').velocity('slideDown', {
      easing: 'easeOutQuart',
      duration: 250
    });
  }

  function _hideAccordion($accordion) {
    _deactivateAccordion($accordion);
    $accordion.find('.accordion-content').hide();
  }

  function _showAccordion($accordion) {
    _activateAccordion($accordion);
    $accordion.find('.accordion-content').show();
  }

  function _initHoverPairs() {
    $(document).on('mouseenter', '[data-hover-pair]', function(e) {
      var hoverPair = $(this).attr('data-hover-pair');
      $('[data-hover-pair="'+hoverPair+'"]').addClass('-hover');
    }).on('mouseleave', '[data-hover-pair]', function(e) {
      var hoverPair = $(this).attr('data-hover-pair');
      $('[data-hover-pair="'+hoverPair+'"]').removeClass('-hover');
    });

    $('.article-with-hover a').on('mouseenter', function(e) {
      $(this).closest('.article').addClass('-hover');
    }).on('mouseleave', function(e) {
      $(this).closest('.article').removeClass('-hover');
    });
  }

  // Track ajax pages in Analytics
  function _trackPage() {
    if (typeof ga !== 'undefined') { ga('send', 'pageview', document.location.href); }
  }

  // Track events in Analytics
  function _trackEvent(category, action) {
    if (typeof ga !== 'undefined') { ga('send', 'event', category, action); }
  }

  // Disable transiton on one element on call
  function _disableSingleTransition($element, delay) {
    $element.css('transition', 'none');
    setTimeout(function() {
      $element.attr('style', '');
    }, delay);
  }

  // Disabling transitions on certain elements on resize
  function _disableTransitions() {
    $.each(transitionElements, function() {
      $(this).css('transition', 'none');
    });
  }

  function _enableTransitions() {
    $.each(transitionElements, function() {
      $(this).attr('style', '');
    });
  }

  // Called in quick succession as window is resized
  function _resize() {

    // Check breakpoint indicator in DOM ( :after { content } is controlled by CSS media queries )
    breakpointIndicatorString = window.getComputedStyle(
      document.querySelector('#breakpoint-indicator'), ':after'
    ).getPropertyValue('content')
    .replace(/['"]+/g, '');

    // Determine current breakpoint
    breakpoint_xl = breakpointIndicatorString === 'xl';
    breakpoint_nav = breakpointIndicatorString === 'nav' || breakpoint_xl;
    breakpoint_lg = breakpointIndicatorString === 'lg' || breakpoint_nav;
    breakpoint_md = breakpointIndicatorString === 'md' || breakpoint_lg;
    breakpoint_sm = breakpointIndicatorString === 'sm' || breakpoint_md;
    breakpoint_xs = breakpointIndicatorString === 'xs' || breakpoint_sm;

    breakpoints = [breakpoint_xs,breakpoint_sm,breakpoint_md,breakpoint_lg,breakpoint_nav,breakpoint_xl];

    // Reset inline styles for navigation for medium breakpoint
    if (breakpoint_nav && $('.site-nav .sub-menu')[0].hasAttribute('style')) {
      $('#site-nav .-active').removeClass('-active');
      $('#site-nav .sub-menu[style]').attr('style', '');
    }
    // Deactivate mobile nav when resizing to large screen
    if (breakpoint_nav) {
      _hideSiteNav();
    }

    // Disable transitions when resizing
    _disableTransitions();

    // Functions to run on resize end
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(function() {
      // Re-enable transitions
      _enableTransitions();
      // Reset Acordions
      // _resetAccordions();
    }, 250);

  }

  // Public functions
  return {
    init: _init,
    resize: _resize,
    scrollBody: function(section, duration, delay) {
      _scrollBody(section, duration, delay);
    }
  };

})(jQuery);

// Fire up the mothership
jQuery(document).ready(FBSage.init);

// Zig-zag the mothership
jQuery(window).resize(FBSage.resize);
